<template lang="html">
    <header class="header header--center header--mobile" id="header-sticky">
        <div class="container">
            <div class="header__wrapper">
                <div class="header__left">
                    <logo v-if="!isDrawer" />
                </div>
                <div class="header__right">
					<a href="https://campaign-public.app.carriercrow.com/subscription/zAubsZaMwz?locale=en-US" style="color: #fff;"><button style="background: #814256; padding: 5px 15px; border-radius: 5px;">Get Alerts</button></a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Logo from '@/components/elements/Logo';
import { mapState } from 'vuex';
import { handleStickyHeader } from '@/utilities/helpers';
export default {
    name: 'HeaderMobile',
    components: { Logo },
    computed: {
        ...mapState({
            isDrawer: state => state.app.isDrawer
        })
    },

    methods: {
        handleOpenMenuDrawer() {
            this.$store.commit('app/toggleDrawer', !this.isDrawer);
        }
    },
    mounted() {
        window.addEventListener('scroll', handleStickyHeader);
    }
};
</script>

<style lang="scss" scoped></style>
