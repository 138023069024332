<template lang="html">
    <header :class="`header header--default ${classes}`" id="header-sticky">
        <div class="container">
            <div class="header__left">
                <logo color="white" />
                <logo />
            </div>
            <div class="header__center">
                <menu-default :menu-items="menuItems" class-name="menu" />
            </div>
            <div class="header__right">
                    <a href="https://campaign-public.app.carriercrow.com/subscription/zAubsZaMwz?locale=en-US" style="color: #fff;"><button style="background: #814256; padding: 10px 30px; border-radius: 5px;">Get Alerts</button></a>
            </div>
        </div>
    </header>
</template>

<script>
import { handleStickyHeader } from '@/utilities/helpers';
import MenuDefault from '@/components/shared/menu/MenuDefault';
import { primaryMenu } from '@/static/data/menu.json';
import Logo from '@/components/elements/Logo';
import { mapState } from 'vuex';

export default {
    name: 'HeaderDefault',
    components: { Logo, MenuDefault },
    props: {
        classes: {
            type: String,
            default: () => ''
        }
    },
    computed: {
        ...mapState({
            isSearch: state => state.app.isSearch,
            isDrawer: state => state.app.isDrawer
        }),
        menuItems() {
            return primaryMenu;
        }
    },
    methods: {
        handleOpenSearchBox() {
            this.$store.commit('app/toggleSearch', true);
        }
    },
    mounted() {
        window.addEventListener('scroll', handleStickyHeader);
    }
};
</script>

<style lang="scss" scoped>
	.header {
		color: #282e3a;
		background: #fff;
	}
</style>
