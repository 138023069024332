import { render, staticRenderFns } from "./WidgetFooterQuickLink.vue?vue&type=template&id=08efc852&scoped=true&lang=html&"
import script from "./WidgetFooterQuickLink.vue?vue&type=script&lang=js&"
export * from "./WidgetFooterQuickLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08efc852",
  null
  
)

export default component.exports